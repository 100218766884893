import types from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";

const isPasswordStrong = (password) => {
  if (
    password &&
    password.length > 7 &&
    // eslint-disable-next-line no-useless-escape
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) &&
    /\d/.test(password) &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password)
  )
    return true;
  return false;
};

export const isSessionExpired = (error) => {
  if (
    (error &&
      error.response &&
      error.response.data &&
      error.response.data === "SESSION_EXPIRED") ||
    error === "SESSION_EXPIRED"
  )
    return true;
  return false;
};

export const setUser = (data) => {
  return {
    type: types.SET_USER,
    data,
  };
};

export const getEnv = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_ENV, data: null });

      const { data } = await axios.get("/api/env");

      dispatch({ type: types.SET_ENV, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const getModules = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/ippis-modules");

      dispatch({ type: types.SET_IPPIS_MODULES, data });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const selectModule = (mod, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/select-module", {
        id: mod.id,
      });

      dispatch({
        type: types.SET_SELECTED_MODULE,
        data: mod,
      });

      history.push(mod.to);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

export const login = (credentials, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/logins", credentials);

      try {
        const { data: modules } = await axios.get("/api/ippis-modules");

        dispatch({ type: types.SET_IPPIS_MODULES, data: modules });

        const { data } = await axios.get("/api/auth/users/me");

        delete data.selectedEntitySectorId;

        dispatch({
          type: types.SET_USER,
          data: data,
        });

        history.push("/home");
      } catch (error) {
        if (isSessionExpired(error)) {
          dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
        }
        showError(error);
      }

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const logout = (history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/auth/logins");
    

      dispatch({
        type: types.SET_USER,
        data: {},
      });

      dispatch({
        type: types.SET_SELECTED_MODULE,
        data: {},
      });
      dispatch({ type: types.END_LOADING });
      dispatch({
        type: types.SET_SELECTED_ENTITY_SECTOR,
        data:{},
      });

      dispatch({ type: types.SET_IPPIS_MODULES, data:[] });

      dispatch({ type: types.CLEAN_STATE });
      dispatch({ type: types.LOGOUT });
      
      history.push("/");

    } catch (error) {
      setUser({});
      dispatch({ type: types.END_LOADING });
      history.push("/");
    }
  };
};

export const getUser = (history, location) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/auth/users/me");

      delete data.selectedEntitySectorId;

      try {
        const { data: modules } = await axios.get("/api/ippis-modules");

        dispatch({
          type: types.SET_USER,
          data: data,
        });

        dispatch({ type: types.SET_IPPIS_MODULES, data: modules });

        const mod = modules.find(({ id }) => id === data.selectedModule);

        dispatch({ type: types.SET_SELECTED_MODULE, data: mod || {} });

        delete data.selectedModule;

        setUser(data);

          history.push(mod?.to || "/home");

        dispatch({ type: types.END_LOADING });
      } catch (error) {
        await axios.delete("/api/auth/logins");
        dispatch({ type: types.SET_SELECTED_MODULE, data: {} });
        dispatch({
          type: types.SET_USER,
          data: {},
        });
        dispatch({ type: types.CLEAN_STATE });
        dispatch({ type: types.LOGOUT });
        if (!location.pathname.includes("/reset-password")) history.push("/");
      }
    } catch (error) {
      await axios.delete("/api/auth/logins");
      dispatch({ type: types.SET_SELECTED_MODULE, data: {} });
      dispatch({
        type: types.SET_USER,
        data: {},
      });
      dispatch({ type: types.CLEAN_STATE });
      dispatch({ type: types.LOGOUT });
      if (!location.pathname.includes("/reset-password")) history.push("/");
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const refreshBRDBalance = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/auth/users/me");

      dispatch({
        type: types.SET_USER,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const selectEntitySector = (entitySectorId, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/select-entity-sector", {
        id: entitySectorId,
      });

      const { data } = await axios.get("/api/auth/users/me");

      // let selectedEntitySector = {};

      // if (!!data.selectedEntitySectorId) {
      //   selectedEntitySector = data.entitySectors.find(
      //     ({ id }) => id === data.selectedEntitySectorId
      //   );
      // }

      delete data.selectedEntitySectorId;

      dispatch({
        type: types.CLEAN_STATE,
      });

      dispatch({
        type: types.SET_SELECTED_ENTITY_SECTOR,
        entitySectorId,
      });

      dispatch({
        type: types.SET_USER,
        data: data,
      });

      history.push(history.location.pathname);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

// export const getSelectedEntitySector = () => {
//   return async (dispatch) => {
//     try {
//       const { data } = await axios.get("/api/auth/selected-entity-sector");

//       dispatch({
//         type: types.SET_SELECTED_ENTITY_SECTOR,
//         entitySectorId: data.selectedEntitySectorId,
//       });
//     } catch (error) {
//       showError(error);
//     }
//   };
// };

export const resetPassword = (email, cb) => {
  cb(true, false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      await axios.post("/api/auth/reset-password", { email });

      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const changePassword = (credentials, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/auth/change-password",
        credentials
      );

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.SET_STRONG_PASSWORD, data: true });

      showSuccess(data.message);

      history.push("/");
    } catch (error) {
      dispatch({ type: types.SET_STRONG_PASSWORD, data: false });

      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const register = (user, modelDismiss) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/auth/users", user);

      showSuccess(data.message);

      dispatch({ type: types.END_LOADING });

      modelDismiss.click();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getAnalytics = (showLoader) => {
  return async (dispatch) => {
    try {
      showLoader(true);

      const { data } = await axios.get("/api/dashboard/analytics");

      dispatch({
        type: types.SET_ANALYTICS,
        data: data,
      });

      showLoader(false);
    } catch (error) {
      console.log("ddd", error);
      showLoader(false);

      showError(error);
    }
  };
};

export const changeCurrentEmployment = (formData, cb) => {
  return async (dispatch) => {
    try {
      cb(true, false);

      const { data } = await axios.put("/api/auth/select-employment", formData);

      dispatch({
        type: types.SET_CURRENT_EMPLOYMENT,
        data: data,
      });

      cb(false, true);
    } catch (error) {
      cb(false, false);

      showError(error);
    }
  };
};

export const requestOTP = (credentials, cb) => {
  return async (dispatch) => {
    cb(true, false, null);
    try {
      dispatch({ type: types.START_LOADING });

      const res = await axios.post("/api/auth/request-otp", credentials);

      if (res.data.message !== "Authenticated") showSuccess(res.data.message);

      cb(false, true, res.data.seconds);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      cb(false, false, null);
      showError(error);
    }
  };
};

export const changeOTPSetting = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/auth/otp-setting");

      dispatch({ type: types.SET_OTP_SETTING });

      // showSuccess(data.message);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
