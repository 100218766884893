import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import {
  login,
  selectModule,
  resetPassword,
  logout,
  selectEntitySector,
  requestOTP,
} from "../store/common/actions";

import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

import { isEmpty } from "lodash";
import axios from "axios";
import CustomCountdown from "./common/components/CustomCountdown";

const Home = (props) => {
  const { user, loading, login, resetPassword, requestOTP, env } = props;

  const history = useHistory();
  const location = useLocation();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [onWait, setOnWait] = useState(false);
  const [wasReset, setWasReset] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [resending, setResending] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(120);
  const pin1Ref = useRef(null);
  const pin2Ref = useRef(null);
  const pin3Ref = useRef(null);
  const pin4Ref = useRef(null);
  const [pin, setPin] = useState({ one: "", two: "", three: "", four: "" });
  useEffect(
    () => {
      if (!isEmpty(user)) history.push("/home");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
    ipAddress: "",
  });

  const handleCleanForm = () => {
    setCredentials({
      email: "",
      password: "",
      showPassword: false,
    });
    setPin({ one: "", two: "", three: "", four: "" });
    setRemainingSeconds(120);
    setFormStep(1);
    setForgotPassword(false);
    setResending(false);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      handleCleanForm();
    }
  }, [user]);

  const canVerify = () => {
    return (
      remainingSeconds > 0 &&
      pin.one &&
      pin.two &&
      pin.three &&
      pin.four &&
      !loading &&
      !resending
    );
  };

  const handleRequestOTP = () => {
    requestOTP(
      {
        email: credentials.email,
        password: credentials.password,
        ipAddress: credentials.ipAddress,
      },
      (status, res, data) => {
        console.log(data, res, status);
        setResending(status);
        if (data && res) {
          setRemainingSeconds(data);
          setFormStep(2);
        } else if (!data && res) {
          window.location.reload();
        }
      }
    );
  };

  const onLogin = async (e) => {
    e.preventDefault();

    if (formStep === 1 && env === "prod") {
      handleRequestOTP();
    } else if (formStep === 2 || env !== "prod") {
      login(
        {
          email: credentials.email,
          otpCode:
            env === "prod"
              ? `${pin.one}${pin.two}${pin.three}${pin.four}`
              : undefined,
          password: env !== "prod" ? credentials.password : undefined,
          wasLoggedIn: env === "prod" ? false : true,
          ipAddress: credentials.ipAddress,
        },
        history
      );
    }
  };

  useEffect(() => {
    getClientIp();
  }, []);

  const getClientIp = async () => {
    try {
      const { data } = await axios.get(`https://api.ipify.org`);
      setCredentials({
        ...credentials,
        ipAddress: data,
      });
    } catch (error) {}
  };

  const handleReset = (e) => {
    e.preventDefault();
    if (credentials.email.length)
      resetPassword(credentials.email, (loading, status) => {
        setOnWait(loading);
        setWasReset(status);
      });
  };

  const handlePINChange = (e, pinName) => {
    const value = e.target.value;
    if (isNaN(value) || value.length > 1) {
      return;
    }

    // Update the PIN
    const updatedPin = { ...pin };
    updatedPin[pinName] = value;

    // Move to the next input field if the current input is not empty
    if (value !== "") {
      if (pinName === "one") pin2Ref.current.focus();
      if (pinName === "two") pin3Ref.current.focus();
      if (pinName === "three") pin4Ref.current.focus();
    }

    setPin(updatedPin);
    e.preventDefault();
  };

  const onPasteAssignPin = (e) => {
    const value = e.clipboardData.getData("text/plain");
    if (!isNaN(value)) {
      const codesArray = value.split("");
      setPin({
        one: codesArray[0] ?? "",
        two: codesArray[1] ?? "",
        three: codesArray[2] ?? "",
        four: codesArray[3] ?? "",
      });
      pin4Ref.current.focus();
    }
    e.preventDefault();
  };

  const handleOnDeletePin = (e, pinName) => {
    if (
      (e.key === "Delete" || e.key === "Backspace") &&
      e.target.value === ""
    ) {
      if (pinName === "four") pin3Ref.current.focus();
      if (pinName === "three") pin2Ref.current.focus();
      if (pinName === "two") pin1Ref.current.focus();
    }
  };

  const handleGetHashedEmail = (email) => {
    const emailArray = email.split("@");
    const firstPart = emailArray[0];
    const atPart = emailArray[1];
    if (firstPart.length <= 3) return `${firstPart[0]}******@${atPart}`;
    else if (firstPart.length <= 5)
      return `${firstPart.substring(0, 2)}******@${atPart}`;
    else return `${firstPart.substring(0, 3)}******@${atPart}`;
  };

  return (
    <React.Fragment>
      {isEmpty(user) && (
        <div className="container-fluid px-0">
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#007bff", height: "2px" }}></div>
            <div style={{ backgroundColor: "#199e05", height: "1px" }}></div>
            <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
          </div>

          <div
            className="row justify-content-center pt-3 mx-0 px-0 text-center"
            style={{
              marginTop: "-142px",
            }}
          >
            {!forgotPassword && (
              <div
                className="col-12 col-md-6 col-lg-4 bg-light rounded elevated p-4 mb-6"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                {formStep === 1 ? (
                  <form onSubmit={onLogin}>
                    <h1 className="h3 mb-3 font-weight-normal">Login</h1>

                    <FormControl fullWidth variant="outlined" className="mb-3">
                      <InputLabel htmlFor="email">Enter your email</InputLabel>
                      <OutlinedInput
                        // size="small"
                        style={{
                          // height: "35px!important",
                          position: "relative",
                          borderRadius: "8px",
                          padding: "1px!important",
                        }}
                        id="email"
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={credentials.email}
                        onChange={(e) => {
                          const email = e.target.value;
                          setCredentials({ ...credentials, email });
                        }}
                        label="Enter your email"
                      />
                    </FormControl>

                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput
                        // size="small"
                        style={{
                          // height: "35px!important",
                          position: "relative",
                          borderRadius: "8px",
                          padding: "1px!important",
                        }}
                        id="password"
                        placeholder="Password"
                        name="password"
                        type={credentials.showPassword ? "text" : "password"}
                        value={credentials.password}
                        onChange={(e) => {
                          const password = e.target.value;
                          setCredentials({ ...credentials, password });
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setCredentials({
                                  ...credentials,
                                  showPassword: !credentials.showPassword,
                                })
                              }
                              edge="end"
                            >
                              {credentials.showPassword ? (
                                <span className="material-icons">
                                  visibility_off
                                </span>
                              ) : (
                                <span className="material-icons">
                                  visibility
                                </span>
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>

                    <div className="text-right">
                      <Button
                        onClick={() => setForgotPassword(true)}
                        className="mb-3 pr-0"
                      >
                        Forgot password?
                      </Button>
                    </div>

                    {!loading && (
                      <Button
                        type="submit"
                        disabled={loading}
                        variant="contained"
                        disableElevation
                        className="btn btn-block"
                        size="large"
                      >
                        Login
                      </Button>
                    )}

                    {loading && (
                      <Button
                        variant="contained"
                        disableElevation
                        className="btn btn-block"
                        size="large"
                      >
                        Connecting...
                      </Button>
                    )}

                    <p className="mt-4 mb-3 text-muted">
                      If you don't remember your email. <br />
                      Contact your HR for help.
                    </p>
                  </form>
                ) : (
                  <form onSubmit={onLogin}>
                    <div className="card bg-white mb-5 mt-5 border-0">
                      <div className="card-body px-5 py-0  text-center">
                        <h3 className="font-weight-bold py-3">
                          2-Step Verification
                        </h3>
                        <p style={{ color: "#696969" }}>
                          A One-Time <strong>verification code</strong> has been
                          sent <br />
                          to your email{" "}
                          <strong>
                            {handleGetHashedEmail(credentials.email)}
                          </strong>
                        </p>

                        <div className="otp-field mb-3">
                          <input
                            ref={pin1Ref}
                            type="number"
                            placeholder="-"
                            maxLength="1"
                            value={pin.one}
                            onPaste={onPasteAssignPin}
                            onChange={(e) => handlePINChange(e, "one")}
                            onKeyUp={(e) => handleOnDeletePin(e, "one")}
                            autoFocus
                          />
                          <input
                            ref={pin2Ref}
                            type="number"
                            placeholder="-"
                            maxLength="1"
                            value={pin.two}
                            onPaste={onPasteAssignPin}
                            onChange={(e) => handlePINChange(e, "two")}
                            onKeyUp={(e) => handleOnDeletePin(e, "two")}
                          />
                          <input
                            ref={pin3Ref}
                            type="number"
                            placeholder="-"
                            maxLength="1"
                            value={pin.three}
                            onPaste={onPasteAssignPin}
                            onChange={(e) => handlePINChange(e, "three")}
                            onKeyUp={(e) => handleOnDeletePin(e, "three")}
                          />
                          <input
                            ref={pin4Ref}
                            type="number"
                            placeholder="-"
                            maxLength="1"
                            value={pin.four}
                            onPaste={onPasteAssignPin}
                            onChange={(e) => handlePINChange(e, "four")}
                            onKeyUp={(e) => handleOnDeletePin(e, "four")}
                          />
                        </div>

                        {remainingSeconds > 0 && (
                          <div className="mb-3">
                            <CustomCountdown
                              remainingSeconds={remainingSeconds}
                              setRemainingSeconds={setRemainingSeconds}
                            />
                          </div>
                        )}

                        <div
                          className="d-flex align-items-center justify-content-between mx-auto mb-2"
                          style={{ width: "180px" }}
                        >
                          <Button
                            className="mx-2"
                            variant="contained"
                            type="submit"
                            disabled={canVerify() ? false : true}
                          >
                            Verify
                          </Button>
                          <Button
                            className="mx-2"
                            variant="outlined"
                            color="primary"
                            type="button"
                            disabled={remainingSeconds > 0}
                            onClick={() => {
                              setResending(true);
                              handleRequestOTP();
                            }}
                          >
                            Resend
                          </Button>
                        </div>
                        <Button
                          variant="text"
                          type="button"
                          className="mt-4"
                          onClick={handleCleanForm}
                          // startIcon={
                          //   <span className="material-icons-round mr-1">
                          //     arrow_back
                          //   </span>
                          // }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            )}

            {forgotPassword && (
              <div className="col-12 col-md-6 col-lg-4 bg-light rounded elevated p-4 mb-6">
                <h1 className="h3 mb-3 font-weight-normal">Reset password</h1>
                {wasReset && (
                  <Alert severity="success" className="mb-3">
                    <AlertTitle>
                      Please, check your email to continue.
                    </AlertTitle>
                    The link to reset your password was sent to your email.
                  </Alert>
                )}

                {!wasReset && (
                  <form onSubmit={handleReset}>
                    <TextField
                      // size="small"
                      style={{
                        height: "35px!important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px!important",
                      }}
                      fullWidth
                      autoFocus
                      variant="outlined"
                      label="Enter your email"
                      placeholder="Email"
                      className="mb-3"
                      value={credentials.email}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          email: e.target.value,
                        })
                      }
                      disabled={loading || onWait}
                    />

                    {!onWait && (
                      <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        className="btn btn-block text-light"
                        size="large"
                        disabled={loading || credentials.email.length === 0}
                      >
                        Reset password
                      </Button>
                    )}

                    {onWait && (
                      <Button
                        type="button"
                        variant="contained"
                        disableElevation
                        className="btn btn-block text-light"
                        size="large"
                        disabled={loading || onWait}
                      >
                        Connecting...
                      </Button>
                    )}
                  </form>
                )}

                <Button
                  onClick={() => {
                    setForgotPassword(false);
                    setOnWait(false);
                    setWasReset(false);
                  }}
                  className="pr-0 mt-3"
                  disabled={loading || onWait}
                >
                  <span className="material-icons">
                    keyboard_double_arrow_left
                  </span>{" "}
                  Back to Login
                </Button>

                {!wasReset && (
                  <p className="mt-4 text-muted">
                    If you don't remember your email. <br />
                    Contact your HR for help.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {/* {!isEmpty(user) && (
        <>
          <WelcomePageMenuBar />
          <WelcomePage />
        </>
      )} */}
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user,
  isFetchingUser,
  loading,
  ippisModules,
  env,
}) => {
  return { user, isFetchingUser, loading, ippisModules, env };
};
export default connect(mapStateToProps, {
  login,
  resetPassword,
  logout,
  selectModule,
  selectEntitySector,
  requestOTP,
})(Home);
