import { defaultState } from "./state";

import TYPES from "./action-types";
import COM_TYPES from "../common/action-types";
const types = { ...COM_TYPES, ...TYPES };

const lookup = (state = defaultState.lookup, action) => {
  switch (action.type) {
    case types.SET_DISTRICTS:
      return { ...state, districts: action.data };

    case types.SET_COUNTRIES:
      return { ...state, countries: action.data };

    case types.SET_DEGREES:
      return { ...state, degrees: action.data };

    case types.SET_EDUCATIONAL_INSTITUTIONS:
      return { ...state, educationalInstitutions: action.data };

    case types.SET_QUALIFICATIONS:
      return { ...state, qualifications: action.data };

    case types.SET_CERTIFICATES:
      return { ...state, certificates: action.data };

    case types.SET_LANGUAGES:
      return { ...state, languages: action.data };

    case types.SET_DISABILITIES:
      return { ...state, disabilities: action.data };

    case types.SET_DISABILITY_LEVELS:
      return { ...state, disabilityLevels: action.data };

    case types.SET_PUBLICATION_TYPES:
      return { ...state, publicationTypes: action.data };

    case types.CLEAN_STATE:
      return defaultState.lookup;

    default:
      return state;
  }
};

const userEducations = (state = defaultState.userEducations, action) => {
  switch (action.type) {
    case types.SET_USER_EDUCATIONS:
      return action.data;

    case types.ADD_OR_UPDATE_USER_EDUCATION: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }

    case types.DELETE_USER_EDUCATION: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.userEducations;

    default:
      return state;
  }
};

const userExperiences = (state = defaultState.userExperiences, action) => {
  switch (action.type) {
    case types.SET_USER_EXPERIENCES:
      return action.data;

    case types.ADD_OR_UPDATE_USER_EXPERIENCE: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }

    case types.DELETE_USER_EXPERIENCE: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.userExperiences;

    default:
      return state;
  }
};

const userLanguages = (state = defaultState.userLanguages, action) => {
  switch (action.type) {
    case types.SET_USER_LANGUAGES:
      return action.data;

    case types.ADD_OR_UPDATE_USER_LANGUAGE: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }

    case types.DELETE_USER_LANGUAGE: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.userLanguages;

    default:
      return state;
  }
};

const userPublications = (state = defaultState.userPublications, action) => {
  switch (action.type) {
    case types.SET_USER_PUBLICATIONS:
      return action.data;

    case types.ADD_OR_UPDATE_USER_PUBLICATION: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }

    case types.DELETE_USER_PUBLICATION: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.userPublications;

    default:
      return state;
  }
};

const userDisabilities = (state = defaultState.userDisabilities, action) => {
  switch (action.type) {
    case types.SET_USER_DISABILITIES:
      return action.data;

    case types.ADD_OR_UPDATE_USER_DISABILITY: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }

    case types.DELETE_USER_DISABILITY: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.userDisabilities;

    default:
      return state;
  }
};

const userCertificates = (state = defaultState.userCertificates, action) => {
  switch (action.type) {
    case types.SET_USER_CERTIFICATES:
      return action.data;

    case types.ADD_OR_UPDATE_USER_CERTIFICATE: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }

    case types.DELETE_USER_CERTIFICATE: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.userCertificates;

    default:
      return state;
  }
};

const userReferees = (state = defaultState.userReferees, action) => {
  switch (action.type) {
    case types.SET_USER_REFEREES:
      return action.data;

    case types.ADD_OR_UPDATE_USER_REFEREE: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }

    case types.DELETE_USER_REFEREE: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.userReferees;

    default:
      return state;
  }
};

const userCV = (state = defaultState.userCV, action) => {
  switch (action.type) {
    case types.SET_USER_CV:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.userCV;

    default:
      return state;
  }
};

const userProfileStatus = (state = defaultState.userProfileStatus, action) => {
  switch (action.type) {
    case types.SET_USER_PROFILE_STATUS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.userProfileStatus;

    default:
      return state;
  }
};

const rebApplicantPreferences = (
  state = defaultState.rebApplicantPreferences,
  action
) => {
  switch (action.type) {
    case types.SET_REB_APPLICANT_PREFERENCES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.rebApplicantPreferences;

    default:
      return state;
  }
};


const userTrainingDetails = (state = defaultState.userTrainingDetails, action) => {
  switch (action.type) {
    case types.SET_USER_TRAINING_DETAILS:
      return action.data;

    case types.ADD_OR_UPDATE_TRAINING_DETAIL: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }


    case types.CLEAN_STATE:
      return defaultState.userTrainingDetails;

    default:
      return state;
  }
};

export default {
  lookup,
  userEducations,
  userExperiences,
  userLanguages,
  userPublications,
  userDisabilities,
  userCertificates,
  userReferees,
  userCV,
  userProfileStatus,
  rebApplicantPreferences,
  userTrainingDetails
};
