import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Skeleton,
  InputAdornment,
  Tooltip,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  saveEmployeeWithhold,
  deleteEmployeeWithHold,
  getEmployeeWithholds,
  getCreditors,
  getCalculationMethods,
  getBasePayrollComponents,
  getBanks,
  downloadEmployeeDeductionsReport,
} from "../../../store/payroll/actions";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useTheme } from "@mui/material/styles";
import { showError } from "../../toastify";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import NumberFormat from "react-number-format";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import TableSkeleton from "../../common/components/TableSkeleton";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import RejectionReasonDialog from "../components/RejectionReasonDialog";
import SearchBox from "../../common/components/SearchBox";
import PdfViewer from "../../common/components/PdfViewer";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

const EmployeeDeductions = (props) => {
  const {
    user,
    loading,
    onWait,
    selectedEmployee,
    saveEmployeeWithhold,
    deleteEmployeeWithHold,
    employeeWithholds,
    creditors,
    banks,
    calculationMethods,
    basePayrollComponents,
    getEmployeeWithholds,
    getCreditors,
    getCalculationMethods,
    getBasePayrollComponents,
    getBanks,
    downloadEmployeeDeductionsReport,
    env,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [creditor, setCreditor] = useState(null);
  const [calculationMethod, setCalculationMethod] = useState(null);
  const [basePayrollComponent, setBasePayrollComponent] = useState(null);
  const [selectedEmployeeWithhold, setSelectedEmployeeWithhold] =
    useState(null);
  const [confirmRemoveEmployeeWithhold, setConfirmRemoveEmployeeWithhold] =
    useState(false);
  const [showSupportingDocument, setShowSupportingDocument] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [filteredWithholdRequests, setFilteredWithholdRequests] = useState([
    ...employeeWithholds,
  ]);
  const [baseUrl, setBaseUrl] = useState(
    window.location.protocol + "//" + window.location.host
  );

  const [documentUrl, setDocumentUrl] = useState(null);
  const [formData, setFormData] = useState({
    employeePositionId: selectedEmployee.employeePositionId,
    creditorId: "",
    amount: 0,
    totalAmount: 0,
    rate: 0,
    startDate: new Date(),
    supportingDocument: "",
    //id: 0,
  });

  useEffect(() => {
    if (!creditors.length) getCreditors();
    if (!calculationMethods.length) getCalculationMethods();
    if (!basePayrollComponents.length) getBasePayrollComponents();
    if (!banks.length) getBanks();
  }, []);

  useEffect(
    () => {
      if (!employeeWithholds.length) getEmployeeWithholds(setShowLoader);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const filtered = [...employeeWithholds].filter(
      ({
        creditorName,
        firstName,
        lastName,
        positionName,
        employeeId,
        bankName,
        acountNumber,
        entityName,
        unitName,
      }) =>
        (
          (creditorName || "") +
          (firstName || "") +
          (lastName || "") +
          (positionName || "") +
          (employeeId || "") +
          (bankName || "") +
          (acountNumber || "") +
          (entityName || "") +
          (unitName || "")
        )
          .toLowerCase()
          .includes(searchTeam.toLowerCase())
    );

    setFilteredWithholdRequests(filtered);
  }, [searchTeam, employeeWithholds]);

  const [add, setAdd] = useState(false);
  const [isRate, setIsRate] = useState(false);

  const [errors, setErrors] = useState({
    creditorHasError: false,
    amountHasError: false,
    startDateHasError: false,
    rateAmountHasError: false,
    supportingDocumentHasError: false,
  });

  const formValidator = () => {
    const error = {
      creditorHasError: false,
      hasError: false,
      amountHasError: false,
      rateHasError: false,
      startDateHasError: false,
      totalAmountHasError: false,
      supportingDocumentHasError: false,
    };

    if (!formData.creditorId) {
      error.creditorHasError = true;
      error.hasError = true;
    }
    if (!documentUrl) {
      error.supportingDocumentHasError = true;
      error.hasError = true;
    }
    if (!formData.startDate) {
      error.startDateHasError = true;
      error.hasError = true;
    }

    if (!formData.amount && !isRate) {
      error.amountHasError = true;
      error.hasError = true;
    }

    if (!formData.rate && isRate) {
      error.rateHasError = true;
      error.hasError = true;
    }

    if (!formData.totalAmount && !disableTotalAmount) {
      error.totalAmountHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      if (
        !disableTotalAmount &&
        parseInt(formData.amount) > parseInt(formData.totalAmount)
      ) {
        showError(
          "Total amount to be deducted must be greater than monthly deduction"
        );
        return false;
      }

      saveEmployeeWithhold(
        formData,
        setFormData,
        setAdd,
        setCreditor
        // canEditCreditor
      );
    }
  };

  useEffect(() => {
    if (!!creditor) {
      setCalculationMethod(
        calculationMethods.find(({ id }) => id === creditor.calculationMethodId)
      );
      setBasePayrollComponent(
        basePayrollComponents.find(
          ({ id }) => id === creditor.basePayrollComponentId
        )
      );
      setFormData({
        ...formData,
        amount: 0,
        rate: creditor.rate,
        startDate: formData.startDate || new Date(),
      });
    } else {
      setCalculationMethod(null);
      setBasePayrollComponent(null);
      setIsRate(false);
      setFormData({
        ...formData,
        rate: 0,
        amount: 0,
        startDate: new Date(),
      });
    }
  }, [creditor]);

  useEffect(() => {
    if (!!calculationMethod) {
      setIsRate(calculationMethod.id === 1 ? false : true);
    }
  }, [calculationMethod]);

  const [disableTotalAmount, setDisableTotalAmount] = useState(true);

  const getNo = (idToSearch, data) => {
    return data.findIndex(({ id }) => id === idToSearch) + 1;
  };
  const onSearch = (term) => {
    setSearchTeam(term);
  };
  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    // console.log(size);
    // let fileSize = 0;
    // if (size < 1000 * 1000 * 1000) {
    //   fileSize = size / 1000 / 1000;
    // }
    return size > 1074176 ? true : false;
  };

  const handleUploadedPDFFile = (event) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, supportingDocumentHasError: false });

    if (convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf") {
      setErrors({ ...errors, supportingDocumentHasError: true });
      showError("File should be pdf format");
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, supportingDocumentHasError: true });
      showError("Allowed file should not exceed 1MB");
    } else {
      const reader = new FileReader();

      reader.onload = (e) => {
        const documentUrl = e.target.result;
        const supportingDocument = documentUrl;
        setFormData({
          ...formData,
          supportingDocument,
        });
        setDocumentUrl(documentUrl);
      };

      reader.readAsDataURL(pdfAttachment);
    }
  };

  return (
    <div style={{ minHeight: "50vh" }}>
      {confirmRemoveEmployeeWithhold && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveEmployeeWithhold}
          message={`Are you sure you want to remove "${selectedEmployeeWithhold.creditorName}"?`}
          setConfirmationDialog={setConfirmRemoveEmployeeWithhold}
          onYes={() => {
            deleteEmployeeWithHold(
              selectedEmployeeWithhold,
              setConfirmRemoveEmployeeWithhold,
              setSelectedEmployeeWithhold
            );
          }}
        />
      )}
      {showSupportingDocument && (
        <PreviewPdfUrl
          showDocument={showSupportingDocument}
          setShowDocument={setShowSupportingDocument}
          document={{
            name: `View Supporting document`,
            url: baseUrl + selectedEmployeeWithhold.documentUrl,
          }}
        />
      )}
      <div>
        {!add && (
          <>
            <div className="row">
              <div className="d-flex  flex-wrap justify-content-end mt-2">
                <div style={{ width: "310px" }} className="mt-1">
                  <SearchBox
                    disabled={!employeeWithholds.length}
                    onSearch={onSearch}
                    placeholder="Search…"
                  />
                </div>
                {/* <button
                  disabled={showLoader}
                  className="btn btn-primary btn-sm ml-2 mt-1"
                  onClick={() => {
                    setAdd(true);
                  }}
                >
                  <i className="fas fa-plus fa-1x  mr-1"></i> Request deduction
                </button> */}

                <button
                  disabled={loading}
                  className="btn btn-secondary btn-sm ml-2 mt-1"
                  onClick={() => {
                    downloadEmployeeDeductionsReport();
                  }}
                >
                  <i className="fas fa-download fa-1x  mr-1"></i>{" "}
                  {loading ? "Wait..." : "Deduction report"}
                </button>
              </div>
            </div>
          </>
        )}

        {add && (
          <div
            className="elevated rounded p-0 mt-3 p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5">
                <div className="row mt-3 ">
                  <div className="mb-3 col-12 col-lg-12">
                    <div className="">
                      <Autocomplete
                        size="small"
                        id="creditor"
                        defaultValue={null}
                        value={creditor}
                        options={creditors}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, creditor) => {
                          setErrors({ ...errors, creditorHasError: false });
                          setCreditor(creditor || null);

                          const creditorId = creditor ? creditor.id : "";

                          setFormData({
                            ...formData,
                            creditorId,
                            id: 0,
                          });
                        }}
                        getOptionLabel={(option) =>
                          getNo(option.id, creditors) +
                          "-" +
                          option.name +
                          (option.calculationMethodId === 2
                            ? " (" + option.calculationMethodName + ")"
                            : "")
                        }
                        renderOption={(props, creditor) => (
                          <Box component="li" {...props}>
                            {getNo(creditor.id, creditors)} - {creditor.name}
                            {creditor.calculationMethodId === 2
                              ? " (" + creditor.calculationMethodName + ")"
                              : ""}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select creditors"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "creditor", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />

                      {errors.creditorHasError && (
                        <small className="text-danger mb-3">
                          Please, select creditor
                        </small>
                      )}
                    </div>
                  </div>

                  <div
                    className={`mb-3 col-12 ${
                      !isRate ? "col-lg-12" : "col-lg-12"
                    }`}
                  >
                    <div className="">
                      <Autocomplete
                        style={{ backgroundColor: "#eee" }}
                        size="small"
                        disabled
                        id="Method"
                        defaultValue={null}
                        value={calculationMethod}
                        options={calculationMethods}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, calculationMethod) => {
                          setCalculationMethod(calculationMethod || null);
                          const calculationMethodId = calculationMethod
                            ? calculationMethod.id
                            : "";
                          setFormData({ ...formData, calculationMethodId });
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, calculationMethod) => (
                          <Box component="li" {...props}>
                            {calculationMethod.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Method"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "calculationMethod", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />

                      {/* {errors.calculationMethod && (
                          <small className="text-danger mb-3">
                            CalculationMethod is required{" "}
                          </small>
                        )} */}
                    </div>
                  </div>

                  {isRate && (
                    <div className="mb-3 col-12 col-lg-12">
                      <div className="">
                        <Autocomplete
                          style={{ backgroundColor: "#eee" }}
                          size="small"
                          disabled
                          id="baseComponent"
                          defaultValue={null}
                          value={basePayrollComponent}
                          options={basePayrollComponents}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, basePayrollComponent) => {
                            setBasePayrollComponent(
                              basePayrollComponent || null
                            );
                            const basePayrollComponentId = basePayrollComponent
                              ? basePayrollComponent.id
                              : "";
                            setFormData({
                              ...formData,
                              basePayrollComponentId,
                            });
                          }}
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, basePayrollComponent) => (
                            <Box component="li" {...props}>
                              {basePayrollComponent.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Base Component"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "baseComponent", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                        {/* {errors.basePayrollComponent && (
                          <small className="text-danger mb-3">
                            Base component is required
                          </small>
                        )} */}
                      </div>
                    </div>
                  )}

                  <div className="mb-3 col-12 col-lg-12">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      disabled
                      size="small"
                      fullWidth
                      name="acountNumber"
                      type="text"
                      label="Acount Number"
                      variant="outlined"
                      placeholder="Acount Number"
                      value={!!creditor ? creditor.acountNumber : ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="mb-3 col-12 col-lg-12">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      disabled
                      size="small"
                      fullWidth
                      name="bank"
                      type="text"
                      label="Bank"
                      variant="outlined"
                      placeholder="Bank"
                      value={!!creditor ? creditor.bankName : ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className="mb-3 col-12 col-lg-12">
                    {!isRate && (
                      <div className="">
                        <NumberFormat
                          fullWidth
                          size="small"
                          label="Monthly deduction"
                          name="amount"
                          variant="outlined"
                          placeholder="RWF 0"
                          prefix={"RWF "}
                          customInput={TextField}
                          value={formData.amount}
                          thousandSeparator={true}
                          onBlur={() => {
                            if (formData.amount === "")
                              setFormData({
                                ...formData,
                                amount: 0,
                              });
                          }}
                          onKeyDown={() => {
                            if (+formData.amount === 0)
                              setFormData({
                                ...formData,
                                amount: "",
                              });
                          }}
                          onChange={(e) => {
                            setErrors({ ...errors, amountHasError: false });
                            const amount = e.target.value.split("RWF ");

                            setFormData({
                              ...formData,
                              amount: !!amount[1]
                                ? amount[1].replace(/,/g, "")
                                : 0,
                            });
                          }}
                        />
                        {errors.amountHasError && (
                          <small className="text-danger mb-2">
                            Amount is required{" "}
                          </small>
                        )}
                      </div>
                    )}

                    {isRate && (
                      <div className="">
                        <TextField
                          style={{ backgroundColor: "#eee" }}
                          size="small"
                          disabled
                          fullWidth
                          name="rate"
                          label="Rate"
                          variant="outlined"
                          placeholder="Rate"
                          value={formData.rate}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setErrors({ ...errors, rateHasError: false });
                            const rate = e.target.value;
                            setFormData({ ...formData, rate });
                          }}
                        />
                        {errors.rateHasError && (
                          <small className="text-danger mb-2">
                            Rate is required{" "}
                          </small>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="mb-3 px-3 col-12 col-lg-12">
                    <div className="row ">
                      <div className="col-9">
                        {disableTotalAmount && (
                          <TextField
                            style={{ backgroundColor: "#eee" }}
                            size="small"
                            disabled
                            fullWidth
                            name="totalAmount"
                            label="Total to be paid"
                            variant="outlined"
                            placeholder="Rate"
                            value="N/A"
                          />
                        )}
                        {!disableTotalAmount && (
                          <NumberFormat
                            fullWidth
                            size="small"
                            label="Total to be paid"
                            name="totalAmount"
                            variant="outlined"
                            autoFocus={true}
                            placeholder="RWF 0"
                            prefix={"RWF "}
                            customInput={TextField}
                            value={formData.totalAmount}
                            thousandSeparator={true}
                            onBlur={() => {
                              if (formData.totalAmount === "")
                                setFormData({
                                  ...formData,
                                  totalAmount: 0,
                                });
                            }}
                            onKeyDown={() => {
                              if (+formData.totalAmount === 0)
                                setFormData({
                                  ...formData,
                                  totalAmount: "",
                                });
                            }}
                            onChange={(e) => {
                              setErrors({
                                ...errors,
                                totalAmountHasError: false,
                              });
                              const totalAmount = e.target.value.split("RWF ");

                              setFormData({
                                ...formData,
                                totalAmount: !!totalAmount[1]
                                  ? totalAmount[1].replace(/,/g, "")
                                  : 0,
                              });
                            }}
                          />
                        )}

                        {errors.totalAmountHasError && (
                          <div className="text-danger mb-2">
                            Total to be paid is required{" "}
                          </div>
                        )}
                      </div>
                      <div className="col-3">
                        <button
                          onClick={() => {
                            setDisableTotalAmount(!disableTotalAmount);
                            setErrors({
                              ...errors,
                              totalAmountHasError: false,
                            });
                            setFormData({
                              ...formData,
                              totalAmount: 0,
                            });
                          }}
                          type="button"
                          className="btn btn-outline-secondary mr-5 btn-sm text-uppercase"
                        >
                          {!disableTotalAmount && (
                            <span className="material-icons">edit_off</span>
                          )}

                          {disableTotalAmount && (
                            <span className="material-icons">
                              mode_edit_outline
                            </span>
                          )}
                        </button>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 col-12 col-lg-12">
                    <div className="">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label="Effective Date"
                          inputFormat="MM/dd/yyyy"
                          value={formData.startDate || new Date()}
                          onChange={(date) => {
                            setErrors({
                              ...errors,
                              startDateHasError: false,
                            });
                            const startDate = date;
                            setFormData({ ...formData, startDate });

                            const startDateHasError = false;
                            setErrors({ ...errors, startDateHasError });
                          }}
                          renderInput={(params) => (
                            <TextField size="small" fullWidth {...params} />
                          )}
                        />

                        {errors.startDateHasError && (
                          <small className="text-danger mt-1">
                            Effective Date is required{" "}
                          </small>
                        )}
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="mb-3 col-12 col-lg-12">
                    <div className="">
                      <fieldset className="form-group border p-2">
                        <legend
                          className="w-auto px-2"
                          style={{
                            fontFamily:
                              '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            fontSize: "0.82rem",
                            lineHeight: "1em",
                            letterSpacing: "0.00938em",
                          }}
                        >
                          <span className="ml-1">
                            Attach supporting document
                          </span>
                        </legend>
                        <div className="form-group1 mb-2">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fas fa-paperclip"></i>
                              </span>
                            </div>
                            <input
                              // disabled={!fiscalYear}
                              type="file"
                              name="file"
                              className="form-control"
                              accept="application/pdf"
                              placeholder="Select pdf file"
                              onChange={(e) => handleUploadedPDFFile(e)}
                            />
                          </div>
                          {errors.supportingDocumentHasError && (
                            <small className="text-danger mb-3">
                              Please, upload supporting document
                            </small>
                          )}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              {documentUrl && (
                <div className="col-12 col-lg-7">
                  {documentUrl && <PdfViewer url={documentUrl} />}
                </div>
              )}
            </div>

            <Divider className="my-1" />
            <div className="d-flex justify-content-center py-0">
              <button
                onClick={onSave}
                type="button"
                className="btn btn-primary text-uppercase mt-3"
                disabled={loading || onWait}
              >
                {onWait ? "Wait..." : "Save"}
              </button>{" "}
              <React.Fragment>
                &nbsp; &nbsp;
                <button
                  onClick={() => {
                    setAdd(false);
                    setCreditor(null);
                    setDisableTotalAmount(true);
                    setFormData({
                      employeePositionId: selectedEmployee.employeePositionId,
                      creditorId: "",
                      amount: 0,
                      rate: 0,
                      totalAmount: 0,
                      startDate: null,
                      // id: 0,
                    });
                  }}
                  type="button"
                  className="btn  btn-secondary text-uppercase mt-3"
                  disabled={loading}
                >
                  Cancel
                </button>
              </React.Fragment>
            </div>
          </div>
        )}

        <React.Fragment>
          <div style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>
            <table className="table-striped table-hover table-sm  fixTableHead mt-3">
              <thead>
                <tr>
                  <th colSpan={12} style={{ textAlign: "center" }}>
                    ACTIVE WITHHOLDS
                  </th>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#f6f6f6" }}>#</td>
                  <td style={{ backgroundColor: "#f6f6f6" }}>Creditor</td>
                  <td style={{ backgroundColor: "#f6f6f6" }}>Institution</td>
                  <td style={{ backgroundColor: "#f6f6f6" }}>Position</td>
                  {/* <td style={{ backgroundColor: "#f6f6f6" }}></td> */}

                  <td
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="text-right"
                  >
                    EffectiveDate
                  </td>
                  {/* <td
                      style={{ backgroundColor: "#f6f6f6" }}
                      className="text-right"
                    >
                      EndDate
                    </td> */}

                  <td
                    style={{ backgroundColor: "#e9ecef" }}
                    className="text-right"
                  >
                    TotalToPay
                  </td>
                  <td
                    style={{ backgroundColor: "#e9ecef" }}
                    className="text-right"
                  >
                    TotalPaid
                  </td>
                  <td
                    style={{ backgroundColor: "#e9ecef" }}
                    className="text-right"
                  >
                    Outstanding
                  </td>
                  <td
                    colSpan={2}
                    style={{ backgroundColor: "#f6f6f6" }}
                    className="text-center"
                  >
                    MonthlyDeduction
                  </td>
                  {/* <td style={{ backgroundColor: "#f6f6f6" }}>Attached</td>
                  <td style={{ backgroundColor: "#f6f6f6" }}>Status</td> */}
                </tr>
              </thead>

              {showLoader ? (
                <TableSkeleton cols={12} />
              ) : (
                <tbody>
                  {filteredWithholdRequests.map((unitWithhold, uaIndex) => (
                    <EmployeeDeductionItem
                      key={uaIndex}
                      unitWithhold={unitWithhold}
                      setConfirmRemoveEmployeeWithhold={
                        setConfirmRemoveEmployeeWithhold
                      }
                      setShowRejectionReason={setShowRejectionReason}
                      setSelectedEmployeeWithhold={setSelectedEmployeeWithhold}
                      setShowSupportingDocument={setShowSupportingDocument}
                      uaIndex={uaIndex}
                    />
                  ))}
                </tbody>
              )}
            </table>
            <NoRecordMessage
              isEmpty={!filteredWithholdRequests.length && !showLoader}
              title="No result found"
            />
            {showRejectionReason && (
              <RejectionReasonDialog
                reason={selectedEmployeeWithhold.rejectReason}
                showRejectionReason={showRejectionReason}
                setShowRejectionReason={setShowRejectionReason}
              />
            )}
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  employeeWithholds,
  creditors,
  calculationMethods,
  basePayrollComponents,
  banks,
}) => {
  return {
    loading,
    onWait,
    employeeWithholds,
    creditors,
    calculationMethods,
    basePayrollComponents,
    banks,
  };
};
export default connect(mapStateToProps, {
  saveEmployeeWithhold,
  deleteEmployeeWithHold,
  getEmployeeWithholds,
  getCreditors,
  getCalculationMethods,
  getBasePayrollComponents,
  getBanks,
  downloadEmployeeDeductionsReport,
})(EmployeeDeductions);

const EmployeeDeductionItem = (props) => {
  const {
    unitWithhold,
    setConfirmRemoveEmployeeWithhold,
    setSelectedEmployeeWithhold,
    setShowRejectionReason,
    setShowSupportingDocument,
    uaIndex,
  } = props;

  return (
    <tr
      style={{
        background: uaIndex % 2 === 0 ? "" : "#f6f7f7",
        borderBottom: "2px dotted rgb(7, 142, 206)!important",
      }}
    >
      <td>{uaIndex + 1}</td>
      <td>
        {unitWithhold.creditorName} <br />{" "}
        <small>
          {unitWithhold.acountNumber} ({unitWithhold.bankName})
        </small>{" "}
      </td>
      <td>{unitWithhold.entityName}</td>

      <td>
        {unitWithhold.positionName}
        <small className="text-primary px-2">
          ({unitWithhold.unitName})
        </small>{" "}
      </td>

      <td className="text-right">
        {unitWithhold.startDate
          ? moment(unitWithhold.startDate).format("yyyy-MM-DD")
          : ""}{" "}
      </td>
      {/* <td className="text-right">
        {unitWithhold.endDate
          ? moment(unitWithhold.endDate).format("yyyy-MM-DD")
          : ""}{" "}
      </td> */}

      <td style={{ backgroundColor: "#e9ecef" }} className="text-right">
        {!!unitWithhold.totalAmount && (
          <>
            <CurrencyFormat
              value={unitWithhold.totalAmount}
              displayType={"text"}
              thousandSeparator={true}
            />
            <span className={`badge text-info`}>RWF</span>
          </>
        )}

        {!unitWithhold.totalAmount && (
          <span className={`badge text-info`}>-</span>
        )}
      </td>
      <td style={{ backgroundColor: "#e9ecef" }} className="text-right">
        {!!unitWithhold.balance && (
          <>
            <CurrencyFormat
              value={unitWithhold.balance}
              displayType={"text"}
              thousandSeparator={true}
            />
            <span className={`badge text-info`}>RWF</span>
          </>
        )}
        {!unitWithhold.balance && <span className={`badge text-info`}>-</span>}
      </td>
      <td style={{ backgroundColor: "#e9ecef" }} className="text-right">
        {!!unitWithhold.totalAmount && (
          <>
            <CurrencyFormat
              value={+unitWithhold.totalAmount - +unitWithhold.balance}
              displayType={"text"}
              thousandSeparator={true}
            />
            <span className={`badge text-info`}>RWF</span>
          </>
        )}
        {!unitWithhold.totalAmount && (
          <span className={`badge text-info`}>-</span>
        )}
      </td>
      <td className="text-right" style={{ borderRight: "none" }}>
        {unitWithhold.calculationMethodId === 1 ? (
          <CurrencyFormat
            value={unitWithhold.amount}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          unitWithhold.rate
        )}
        <span
          className={`badge ml-1 badge-${
            unitWithhold.calculationMethodId === 1
              ? "light text-info"
              : "secondary"
          }`}
        >
          {" "}
          {unitWithhold.calculationMethodId === 1 ? "RWF" : "RATE"}
        </span>
      </td>

      <td className="text-left" style={{ borderLeft: "none" }}>
        <span
          className="badge badge-light text-uppercase text-info"
          style={{ fontSize: "12px" }}
        >
          {"/ " + unitWithhold.basePayrollComponentName}
        </span>
        {/* / {unitWithhold.basePayrollComponentName.replace(/ /g, "")}{" "} */}
      </td>
      {/* <td className="text-left " style={{ borderLeft: "none" }}>
        {unitWithhold.documentUrl && (
          <Tooltip
            style={{ cursor: "pointer" }}
            title={"Attached supporting document"}
            onClick={() => {
              setSelectedEmployeeWithhold(unitWithhold);
              setShowSupportingDocument(true);
            }}
          >
            <AttachFileIcon className="mb-2 mr-2 text-primary mt-2" />
          </Tooltip>
        )}
      </td> */}
      {/* <td className="d-flex align-items-center">
        {unitWithhold.status === "Verified" && (
          <span className="  text-success d-flex align-items-start pt-4">
            <span
              className="material-icons text-success "
              style={{ fontSize: "14px" }}
            >
              verified
            </span>
            <span style={{ fontSize: "10px" }}>Verified</span>
          </span>
        )}

        {unitWithhold.status === "Requested" && (
          <span className="text-primary d-flex align-items-center pt-0">
            <span
              className="material-icons text-primary "
              style={{ fontSize: "14px" }}
            >
              notifications
            </span>
            <span style={{ fontSize: "10px" }}>Requested</span>
          </span>
        )}
        {unitWithhold.status === "Rejected" && (
          <>
            <div className="row ">
              <div className="col-12">
                <span className="text-center d-flex align-items-center">
                  <span
                    className="material-icons text-danger "
                    style={{ fontSize: "14px" }}
                  >
                    cancel
                  </span>
                  <span className="text-danger " style={{ fontSize: "10px" }}>
                    Rejected
                  </span>
                </span>
              </div>
              <div className="col-12">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedEmployeeWithhold(unitWithhold);
                    setShowRejectionReason(true);
                  }}
                  className="text-primary text-center"
                >
                  (view reason)
                </span>
              </div>
            </div>
          </>
        )}

        {unitWithhold.status === "Requested" && (
          <IconButton
            title="Delete"
            className="d-flex align-items-center"
            size="small"
            color="error"
            onClick={() => {
              setSelectedEmployeeWithhold(unitWithhold);
              setConfirmRemoveEmployeeWithhold(true);
            }}
          >
            <span className="material-icons">delete</span>
          </IconButton>
        )}
      </td> */}
    </tr>
  );
};
