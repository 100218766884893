import { Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { selectEntitySector } from "../../../store/common/actions";
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { textCapitalized } from "./Utils";
import { isEmpty } from "lodash";

const EntitySwitcher = (props) => {
  const { user } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!isEmpty(user) && !!user.currentEmployeePosition && (
        <Box className="mr-2">
          <Tooltip
            className="text-capitalized"
            title={
              <>
                {textCapitalized(user?.currentEmployeePosition?.entityName || '')}
                {/* -(
                {textCapitalized(user.currentEmployeePosition.sectorName)}) */}
              </>
            }
            placement="top"
          >
            <span>
              <Button
                variant="text"
                disabled
                disableElevation
                sx={{ textTransform: "capitalize" }}
              >
                { !isEmpty(user) && user?.currentEmployeePosition?.entityName && (
                  <>
                    <span className="text-truncate text-capitalized text-light d-inline d-md-none badge badge-secondary p-2">
                      {textCapitalized(user.currentEmployeePosition.entityName)}
                      {/* -(
                    {textCapitalized(user.currentEmployeePosition.acronym)}) */}
                    </span>
                    <span className="text-truncate  text-capitalized  d-none  d-md-inline badge badge-secondary p-2">
                      {textCapitalized(user.currentEmployeePosition.entityName)}
                      {/* -(
                    {textCapitalized(user.currentEmployeePosition.acronym)}) */}
                    </span>
                  </>
                )}
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, { selectEntitySector })(EntitySwitcher);
