import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getUserTrainingDetails,
  addOrUpdateUserTrainingDetail,
} from "../../../store/profile/actions";
import AdvertSkeleton from "../../common/components/AdvertSkeleton";

export const TrainingDetails = (props) => {
  const {
    loading,
    getUserTrainingDetails,
    addOrUpdateUserTrainingDetail,
  } = props;

  const [userTrainingDetail, setUserTrainingDetail] = useState(null);
  const [neededTraining, setNeededTraining] = useState("");

  const [desiredTraining, setDesiredTraining] = useState("");

  useEffect(() => {
    getUserTrainingDetails(setUserTrainingDetail);
  }, []);

  useEffect(() => {

    setNeededTraining(userTrainingDetail?.neededTraining || "");
    setDesiredTraining(userTrainingDetail?.desiredTraining || "");

  }, [userTrainingDetail]);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    neededTrainingHasError: false,
    desiredTrainingHasError: false,
  });

  const formValidator = () => {
    const error = {
      neededTrainingHasError: false,
      desiredTrainingHasError: false,
      hasError: false,
    };

    if (!neededTraining) {
      error.neededTrainingHasError = true;
      error.hasError = true;
    }

    if (!desiredTraining) {
      error.desiredTrainingHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    if (!formValidator()) {
      addOrUpdateUserTrainingDetail(
        {
          id: userTrainingDetail?.id || "",
          neededTraining: neededTraining,
          desiredTraining: desiredTraining,
        },
        setIsLoading,
        setUserTrainingDetail
      );
    }
  };

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="publications">
        <CardContent>
          {loading && !isLoading ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                {/* title */}
                <div>
                  <Typography variant="h5" style={{ color: "#078ece" }}>
                    Training details
                  </Typography>
                </div>
              </div>
              <div className="px-md-0 mt-4">
                <form onSubmit={handleSubmit}>
                  <div className="form-group col-12">
                    <TextField
                      fullWidth
                      label="Training/Development Needed for Current Role"
                      placeholder="Training/Development Needed for Current Role"
                      id="neededTraining"
                      value={neededTraining || ""}
                      size="small"
                      multiline
                      minRows={4}
                      onChange={(e) => {
                        setNeededTraining(e.target.value);
                        setErrors({ ...errors, neededTrainingHasError: false });
                      }}
                      error={errors.neededTrainingHasError}
                    />

                    {errors.neededTrainingHasError && (
                      <small className="text-danger">
                        Training/Development Needed for Current Role is required
                      </small>
                    )}
                  </div>


                  <div className="form-group col-12">
                    <TextField
                      fullWidth
                      label="Desired Next Role in the current structure (Job Title) "
                      placeholder="Desired Next Role in the current structure (Job Title) "
                      id="desiredTraining"
                      value={desiredTraining || ""}
                      size="small"
                      multiline
                      minRows={4}
                      onChange={(e) => {
                        setDesiredTraining(e.target.value);
                        setErrors({
                          ...errors,
                          desiredTrainingHasError: false,
                        });
                      }}
                      error={errors.desiredTrainingHasError}
                    />

                    {errors.desiredTrainingHasError && (
                      <small className="text-danger">
                        Desired Next Role in the current structure (Job Title) is
                        required
                      </small>
                    )}
                  </div>
                  <div className="form-group col-12 pb-3">
                    <Button
                      variant="contained"
                      type="button"
                      className={`px-5 float-right`}
                      disabled={isLoading}
                      onClick={handleSubmit}
                    >
                      {isLoading
                        ? "Wait..."
                        : !!userTrainingDetail
                        ? "Update"
                        : "Save"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = ({
  user,
  env,
  loading,
  onWait
}) => ({
  user,
  env,
  loading,
  onWait
});

export default connect(mapStateToProps, {
  getUserTrainingDetails,
  addOrUpdateUserTrainingDetail,
})(TrainingDetails);
